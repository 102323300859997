import banner from "./assets/images/home/banner2.webp";
import abhishek from "./assets/images/home/abhishek.webp";
import gagandeep from "./assets/images/home/gagandeep.webp";
import icon1 from "./assets/images/common/mobile-app.png";
import icon2 from "./assets/images/common/blockhain.png";
import icon3 from "./assets/images/common/code.png";
import icon4 from "./assets/images/home/QA.png";
import icon5 from "./assets/images/home/gamepad.png";
import icon6 from "./assets/images/home/ux-design.png";
import icon7 from "./assets/images/home/enterprise.png";
import line from "./assets/images/home/line.png";

import {
  faqItems,
  ourMethodology,
  ourTeam,
  statistics,
  clientsLogo,
  techStackArr,
  caseStudies,
  advisoryBoard,
} from "./data";
import FaqAccordion from "./components/accordion/FaqAccordion";
import { useState } from "react";
import Slider from "react-slick";
import Testimonials from "./components/testimonials/Testimonials";
import { NavLink } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import ContactForm from "./components/contact-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [hoveredLogoIndex, setHoveredLogoIndex] = useState();
  const sliderSettings = {
    className: "center",
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: false,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <main>
      <ToastContainer />
      <Header />
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <div className="banner-content">
                <h1>
                  Your Reliable <br />
                  <span>Custom Software Development</span> Partner
                </h1>
                <p>
                  We specialize in providing software development services,
                  bringing your ideas to life with tailored solutions and
                  cutting-edge technology.
                </p>
                <a href="#contact-us" className="contact primary-btn">
                  Get Free Trial
                </a>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 text-right">
              <img src={banner} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="collaboration">
        <div className="container">
          <div className="line-box">
            <h2 className="title">Our Affiliated Partners</h2>
            <img src={line} alt="" />
          </div>
          <div className="row justify-content-center">
            {clientsLogo.map((item, index) => {
              return (
                <div
                  className="collaboration-box"
                  onMouseEnter={() => setHoveredLogoIndex(item.id)}
                  onMouseLeave={() => setHoveredLogoIndex("")}
                  key={item.id}
                >
                  <img
                    width={130}
                    height={60}
                    className={`logo ${
                      index === 3
                        ? "logo2"
                        : index === 5
                        ? "logo3"
                        : index === 6
                        ? "logo6"
                        : ""
                    }`}
                    src={
                      item.id === hoveredLogoIndex ? item.hoverImg : item.img
                    }
                    alt={item.img}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="circle-wrap">
          <div className="circle"></div>
        </div>
      </section>
      <section className="service-slider" id="services">
        <h2 className="title">Services We Offer</h2>
        <Slider {...sliderSettings}>
          <div className="card">
            <div className="card-img">
              <img src={icon1} width={40} height={40} alt="" />
            </div>
            <h3 className="sub-title">Mobile App Development</h3>
            <p className="desc">
              Innow8 Apps specializes in crafting dynamic mobile app that
              seamlessly integrate innovation and functionality. Our team's
              expertise ensures your app not only meets industry standards but
              also resonates with users, driving your business forward.
            </p>
          </div>
          <div className="card">
            <div className="card-img">
              <img src={icon3} width={40} height={40} alt="" />
            </div>
            <h3 className="sub-title">Web App Development</h3>
            <p className="desc">
              We are your partner in creating powerful web app that combine
              cutting-edge technology with user-centric design. Our dedicated
              team delivers tailored solutions that elevate user experiences and
              drive digital transformation for your business.
            </p>
          </div>
          <div className="card">
            <div className="card-img">
              <img src={icon2} width={40} height={40} alt="" />
            </div>
            <h3 className="sub-title">Blockchain Development</h3>
            <p className="desc">
              At Innow8 Apps, we pioneer blockchain solutions that reimagine
              processes, enhance security, and foster trust across industries.
              Our expertise in harnessing blockchain's potential creates a new
              realm of possibilities for businesses worldwide.
            </p>
          </div>
          <div className="card">
            <div className="card-img">
              <img src={icon7} width={40} height={40} alt="" />
            </div>
            <h3 className="sub-title">Enterprise Software Development</h3>
            <p className="desc">
              We excel in crafting sophisticated enterprise software solutions
              that streamline operations and empower growth. Our expertise lies
              in delivering tailored software that optimizes processes, enhances
              efficiency, and drives innovation for businesses at scale.
            </p>
          </div>
          <div className="card">
            <div className="card-img">
              <img src={icon5} width={40} height={40} alt="" />
            </div>
            <h3 className="sub-title">Game Development</h3>
            <p className="desc">
              Innow8 Apps specializes in game development, bringing captivating
              digital worlds to life. Our team's creativity and technical
              prowess result in engaging gameplay, stunning visuals, and
              interactive narratives that captivate players across platforms.
            </p>
          </div>
          <div className="card">
            <div className="card-img">
              <img src={icon6} width={40} height={40} alt="" />
            </div>
            <h3 className="sub-title">UI/UX Design</h3>
            <p className="desc">
              Innow8 Apps, a top web design company, crafts captivating UI/UX
              solutions that optimize user interactions. Our designs seamlessly
              blend aesthetics with functionality, resulting in intuitive and
              memorable digital experiences.
            </p>
          </div>
          <div className="card">
            <div className="card-img">
              <img src={icon4} width={40} height={40} alt="" />
            </div>
            <h3 className="sub-title">Quality Assurance</h3>
            <p className="desc">
              Quality is paramount at Innow8 Apps. Our dedicated QA team
              meticulously tests every solution to guarantee flawless
              functionality and seamless user experiences, ensuring excellence
              at every turn.
            </p>
          </div>
        </Slider>
      </section>
      <section className="cta">
        <div className="container">
          <div className="cta-bg">
            <div className="cta-bg__flex">
              <h2 className="heading">
                Experience our services <br />
                with a Free Consultation
              </h2>
              <a href="#contact-us" className="consult-btn">
                Consult Now
              </a>
            </div>
          </div>
        </div>
        <div className="circle-wrap">
          <div className="circle"></div>
        </div>
      </section>
      <section className="methoology" id="methodology">
        <div className="container">
          <h2 className="title text-center">Our Methodology</h2>
          <div className="row">
            {ourMethodology.map((item) => {
              return (
                <div
                  className="col-md-6 col-lg-4 col-xl-3 my-col"
                  key={item.id}
                >
                  <div className="card">
                    <img src={item.img} width={66} height={66} alt="" />
                    <h3 className="sub-title">{item.title}</h3>
                    <p className="card-text">{item.para}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="our-team" id="our-team">
        <div className="container">
          <h2 className="title text-center">Our Team</h2>
          <p className="text-center desc">
            Driving Excellence: Founders Leading by Example
          </p>
          <div className="row justify-content-center">
            {ourTeam.map((item) => {
              return (
                <div className="col-md-4 col-lg-4" key={item.id}>
                  <div className="card">
                    <img
                      width={358}
                      height={471}
                      src={item.img}
                      className="img-fluid card-img"
                      alt={item.name}
                    />
                    <h3 className="name">{item.name}</h3>
                    <p className="position">{item.position}</p>
                    <p className="para">{item.desc}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="statistics">
        <div className="container">
          <div className="statistics-bg">
            <div className="row justify-center">
              {statistics.map((item) => {
                return (
                  <div className="col-md-3 col-lg-3" key={item.id}>
                    <div className="box">
                      <img src={item.img} alt="" />
                      <h3>{item.num}</h3>
                      <p>{item.name}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <section className="advisory">
        <div className="container">
          <h2 className="title text-center">Our Advisory Board</h2>
          <div className="row justify-content-center">
            {advisoryBoard.map((item) => {
              return (
                <div className="col-md-6 col-lg-4 adv-col" key={item.id}>
                  <div className="card">
                    <img src={item.img} className="img-fluid card-img" alt="" />
                    <div className="card-body">
                      <div className=" flex-col mb-8">
                        <div className="d-flex justify-content-between">
                          <h4 className="name">{item.name}</h4>
                          <div>
                            <h5 className="year">{item.exp}</h5>
                            <p className="text">Experience</p>
                          </div>
                        </div>
                        <p className="position">
                          {item.position}
                        </p>
                        <p className="company">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="tech-stack" id="tech-stack">
        <div className="container">
          <h2 className="title text-center">Tech Stack That We Follow</h2>
          <div className="row">
            <div className="tech-stack__wrapper">
              {techStackArr.map((item, index) => {
                return (
                  <div className="logo-card" key={item.id}>
                    <img
                      src={item.img}
                      className={`logo ${index === 15 ? "logo2" : ""}`}
                      alt=""
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <Testimonials />
      <section className="case-studies" id="case-studies">
        <div className="container">
          <h2 className="title text-center">Our Latest Case Studies</h2>
          <p className="desc">
            where innovative ideas shine through exemplary execution
          </p>
          {caseStudies.map((item, index) => {
            console.log(item.url, "url");
            return (
              <div className="row my-row" key={item.id}>
                <div className="col-md-6 col-lg-6 my-col">
                  <div
                    className={`case-studies__image ${
                      index === 1 ? "image2" : index === 2 ? "image3" : ""
                    }`}
                  >
                    <img src={item.img} alt="app" />
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 my-col">
                  <div
                    className={`case-studies__content ${
                      index === 1 ? "content2" : index === 2 ? "content3" : ""
                    }`}
                  >
                    <h3 className="sub-title">{item.title}</h3>
                    <p className="info">{item.info}</p>
                    <div className="d-flex justify-content-end">
                      <a
                        href={item.url}
                        className="mt-3 no-underline link d-flex"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Read More{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-4 h-4 ml-2 mt-1"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M8.25 4.5l7.5 7.5-7.5 7.5"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <section className="faq">
        <div className="container">
          <h2 className="title">Frequently Asked Questions</h2>
          <div className="row justify-content-center">
            <div className="col-md-7 col-lg-7">
              <FaqAccordion items={faqItems} />
            </div>
          </div>
        </div>
      </section>
      <section className="contact-form" id="contact-us">
        <div className="container">
          <ContactForm />
        </div>
      </section>
      <Footer />
    </main>
  );
}

export default App;
